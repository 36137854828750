@import "variables";

/**
 * Общий стиль основного контейнера в модуле игры.
 */
@mixin mainComponentWrapper($_isVertical, $_controlPanelWidth, $_padding) {
	display: flex;
	@if $_isVertical { flex-direction: column-reverse; } @else { flex-direction: row; }
	justify-content: space-between;

	width: 100%;

	app-game-control {
		width: $_controlPanelWidth;
		@if $_isVertical {
			margin-top: $_padding;
		}

		div.mc-form-container {
			display: flex;
			flex-direction: column;

			& > * {
				@if $_isVertical {
					width: 100%;
					margin-top: $_padding;
				} @else {
					margin-top: $_padding + 4px;
				}
			}
		}
	}
}

/**
 * Общий стиль рамки.
 */
@mixin mainBorder(
	$top-left: 4px, $top-right: 4px, $bottom-left: 4px, $bottom-right: 4px,
	$hasTop: true, $hasBottom: true, $hasLeft: true, $hasRight: true,
	$borderColor: $color_main_border,
	$borderSize: 1px
	
) {
	@if $hasTop { border-top: $borderSize solid $borderColor; } @else { border-top: none; } 
	@if $hasBottom { border-bottom: $borderSize solid $borderColor; } @else { border-bottom: none; }
	@if $hasLeft { border-left: $borderSize solid $borderColor; } @else { border-left: none; }
	@if $hasRight { border-right: $borderSize solid $borderColor; } @else { border-right: none; }

	border-radius: $top-left $top-right $bottom-right $bottom-left;
	box-sizing: border-box;
}

/**
 * Общий набор стилей для панелей управления.
 */
@mixin panelBackground() {
	background: $panel_background;
	border-radius: 6px;
	// filter: $panel_filter;
	box-shadow: 0 0 4px 0 #00000040;
}

/**
 * Общий набор стилей для внутренних секций панелей управления.
 */
@mixin panelSectionBackground($top-left: 4px, $top-right: 4px, $bottom-left: 4px, $bottom-right: 4px, $background: none) {
	background: $background;
	border-radius: $top-left $top-right $bottom-right $bottom-left;
}

/**
 * Стиль больших кнопок.
 *
 * @param bgColor Цвет подложки.
 */
@mixin buttonStyles($_bgColor, $_fntColor, $_fntWeight: $font_weight_semi_bold, $_fntBaseSize: 14px) {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	
	margin-top: $main-padding;

	font-size: $_fntBaseSize;
	font-weight: $_fntWeight;

	color: $_fntColor;
	background: $_bgColor;
	border: none;
	border-radius: 3px;
	outline: 0;
	cursor: pointer;

	text-transform: uppercase;

	&:disabled {
		color: #fff;
		background: $button_disabled_background;
	}

	@media (min-width: 0) and (max-width: $screen_size_MOBILE) {
		font-size: calc(#{$_fntBaseSize} * 0.7);
	}

	@media (min-width: $screen_size_MOBILE + 1) and (max-width: $screen_size_TABLET) {
		font-size: calc(#{$_fntBaseSize} * 0.93);
	}
	
	//@media (min-width: $screen_size_TABLET + 1) and (max-width: $screen_size_1024) {
	//	font-size: calc(#{$_fntBaseSize} * 0.93);
	//}
	//
	//@media (min-width: $screen_size_1024 + 1px) and (max-width: $screen_size_1152) {
	//	font-size: calc(#{$_fntBaseSize} * 0.93);
	//}
	//
	//@media (min-width: $screen_size_1152 + 1px) and (max-width: $screen_size_1280) {
	//	font-size: calc(#{$_fntBaseSize} * 0.93);
	//}
	//
	//@media (min-width: $screen_size_1280 + 1px) and (max-width: $screen_size_1366) {
	//	font-size: calc(#{$_fntBaseSize} * 0.93);
	//}
}

/**
 * Общий стиль подложки на панели ввода предустановленных значений.
 *
 * @param top-left Верхний левый радиус скругления.
 * @param top-right Верхний правый радиус скругления.
 * @param bottom-left Нижний левый радиус скругления.
 * @param bottom-right Нижний правый радиус скругления.
 */
@mixin panelDataBackground($top-left, $top-right, $bottom-left, $bottom-right) {
	border-radius: $top-left $top-right $bottom-right $bottom-left;
	//background: #191b28;
}

/**
 * Общий стиль элемента input.
 *
 * @param top-left Верхний левый радиус скругления.
 * @param top-right Верхний правый радиус скругления.
 * @param bottom-left Нижний левый радиус скругления.
 * @param bottom-right Нижний правый радиус скругления.
 * @param $fontSize Размер шрифта
 */
@mixin inputStyle($top-left, $top-right, $bottom-left, $bottom-right, $fontSize: 1em, $color: #40372b) {
	color: $color;
	text-align: center;
	font-size: $fontSize;
	font-weight: $font_weight_semi_bold;

	outline: none;
	border: none;
	box-shadow: inset 0 0 0 1px #afb1c8;
	border-radius: $top-left $top-right $bottom-right $bottom-left;
	background: #fff;

	&[invalid="true"] {
		color: red;
		box-shadow: inset 0 0 0 1px red;
	}
	
	&:disabled {
		color: $button_disabled_background;
		box-shadow: inset 0 0 0 1px $button_disabled_background;
	}
}

@mixin smallText($fontSize: 0.9em) {
	color: #4b4f68;
	text-align: center;
	font-size: $fontSize;
}

/**
 * Общий диалоговых окон.
 */
@mixin dialogWindow($panelBackgroundColor: #fff, $border: 2px solid #b09954) {
	width: 100%;
	height: 100%;

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		width: 100%;
		height: 100%;
		padding: $main-padding;

		border-radius: 4px;
		border: $border;
		background: $panelBackgroundColor;
		filter: drop-shadow(2px 4px 6px #fff);

		label {
			color: #4d536d;
			text-align: center;
			font-size: 0.9em;
		}

		button {
			min-height: 40px;
			padding: $main-padding * 0.5;
			
			@include buttonStyles(#01b400, #fff);			
		}
	}
}

@mixin betAmountStyleDesktop() {
	app-base-selector {
		.selector-input-wrapper {
			app-plus-minus-button {
				button {
					background: #F7F7F7;
					font-size: 20px;
				}
			}
			input {
				box-shadow: none !important;
				background: #EDEDED !important;
				font-size: 20px !important;
			}
		}
	}
}

@mixin betAmountStyleTabletMob() {
	app-base-selector {
		.selector-input-wrapper {
			app-plus-minus-button {
				button {
					background: #F7F7F7;
					span {
						font-size: 24px !important;
					}
				}
			}
			input {
				box-shadow: none !important;
				background: #EDEDED !important;
				font-size: 14px !important;
			}
		}
	}
}
