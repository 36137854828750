// -----------------------------------------
//  Константы шрифтов
// -----------------------------------------

$font_primary: 'Montserrat', Open Sans, sans-serif;

//$font_weight_thin: 100;
//$font_weight_light: 300;
$font_weight_regular: 400;
$font_weight_medium: 500;
$font_weight_semi_bold: 600;
//$font_weight_bold: 700;
//$font_weight_black: 900;

$font_size_xs: 12px;
$font_size_sm: 14px;
$font_size_fs: 16px;
$font_size_md: 18px;
$font_size_lg: 20px;

// -----------------------------------------
//  Константы цветов
// -----------------------------------------

$body_background: #f0f0f0;
$body_color: #aeb3c6;

$color_main_border: #a29fa6;

$panel_background: #fff;
$panel_filter: drop-shadow(0px 0px 2px #00000040);

$button_disabled_background: #aeb3c6;

$color_green: #01b400;
$color_golden: #b09955;
$color_yellow: #ffdb3d;

// -----------------------------------------
//  Константы ширин различных экранов
// -----------------------------------------

$screen_size_MOBILE_LOW: 325px;                         // мобильный режим низкого разрешения
$screen_size_MOBILE: 767px;								// мобильный режим
$screen_size_TABLET: 991px;								// планшетный режим
$screen_size_1024: 1024px;								// 4:3, 16:10, 5:3, 16:9
$screen_size_1136: 1136px;								// 16:9
$screen_size_1152: 1152px;								// 4:3, 16:10
$screen_size_1280: 1280px;								// 5:4, 4:3, 3:2, 16:10, 5:3, 16:9
$screen_size_1366: 1366px;								// 16:9
$screen_size_1440: 1440px;								// 1440x900 (8:5)
$screen_size_1536: 1536px;								// 1536x864	5.35%
$screen_size_1600: 1600px;								// 1600x900 (16:9)
$screen_size_1680: 1680px;								// 1680x1050 (8:5)

// -----------------------------------------
//
// -----------------------------------------

$main-padding: 20px;
